//import { Nav, NavLink, NavItem, NavbarBrand, NavbarToggler, Collapse, Navbar } from 'reactstrap';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function Footer() {
    return (
        <>
            <div className="footer">
                <h6 className="footertext">George Lucas Dev | 2018 - 2024</h6>
            </div>
        </>
    );
}